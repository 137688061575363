import './App.css';

//ICONS
import { MdOutlineMail } from 'react-icons/md';
import { FaFacebookSquare } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';

function App() {
  return (
    <div className='container'>
      <div className="container-general">
          <div className="logo-container">
              <img className="logo-brip" width="500" height="400" src="https://res.cloudinary.com/briptravel/image/upload/v1657562407/BripTravel/LOGO-BRIPTRAVELARGENTINA-2018_gzxhxs.png" atl="Brip Travel" />
          </div>
          <div className='tittle-container-brip'>
            <h2> ¡Nos estamos renovando!</h2>
            <h5> ¡Visita nuestras redes o líneas de contacto para más novedades!  </h5>
          </div>
          <div className='container-redes'>
              <div className='redes-child'> 
                <BsInstagram className='icon-style' /> 
                <a className='nombre-redes' href='https://www.instagram.com/bripargentina/'> 
                  Instagram Brip Travel
                </a> 
              </div>

              <div className='redes-child'> 
                <MdOutlineMail className='icon-style' /> 
                <a className='nombre-redes' href='mailto:reservas@briptravel.com'> reservas@briptravel.com </a> 
              </div>

              <div className='redes-child'> 
                <FaFacebookSquare className='icon-style' /> 
                <a className='nombre-redes' href='https://www.facebook.com/bripargentina'> 
                  Facebook Brip Travel
                </a> 
              </div> 
        
          </div>

          <div className='container-redes redes-down'>
              <div className='redes-child'> 
                <img className='imagen-pais' src="https://res.cloudinary.com/briptravel/image/upload/v1657564394/BripTravel/icon-argentina_trnlez.svg" alt="Brip Travel Argentina" />
                <a href='https://api.whatsapp.com/send/?phone=5491152638334&text&type=phone_number&app_absent=0'> 
                  +54 1152638334
                </a> 
              </div>

              <div className='redes-child'>  
                <img className='imagen-pais' src="https://res.cloudinary.com/briptravel/image/upload/v1657568851/BripTravel/peru_vuaegn.png" alt="Brip Travel Peru" />
                <a href='https://api.whatsapp.com/send/?phone=5114800534&text&type=phone_number&app_absent=0'> 
                  +51 14800534
                </a> 
              </div>  
          </div>
      </div>
    </div>
  );
}

export default App;
